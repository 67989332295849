// import petitions from '@/petitions.js'
export default [ 
	{ path: '/', component: () => import('@/components/Home.vue'), alias: '/home' },
	{ path: '/meet-aaron', component: () => import('@/components/About.vue') },
	// { path: '/watch', component: () => import('@/components/Watch.vue') },
	// { path: '/petitions', component: () => import('@/components/Petitions.vue') },
	// ...petitions.map(petition => ({
	// 	path: '/petitions/' + petition.url,
	// 	component: () => import('@/components/Petition.vue'),
	// 	props: { petition },
	// 	meta: {
	// 		title: 'Sign this Petition: ' + petition.title,
	// 		description: petition.descriptionShort,
	// 		socialImage: require('@/assets/petitions/' + petition.url + '.jpg')			
	// 	}
	// })),
	// { path: '/join', component: () => import('@/components/Join.vue') },
	// { path: '/sponsor-advertise', component: () => import('@/components/DonateSponsor.vue') },
	// { path: '/support-politics-explained', alias: '/PoliticsExplained', component: () => import('@/components/DonatePoliticsExplained.vue') },
	{ path: '/team', component: () => import('@/components/Team.vue') },
	{ path: '/team/:volunter_pattern', component: () => import('@/components/Team.vue') },
	{ path: '/donate', component: () => import('@/components/Donate.vue') },
	{ path: '/priorities', component: () => import('@/components/Priorities.vue') },
	{ path: '/contact', component: () => import('@/components/Contact.vue') },
	{ path: '/vote', component: () => import('@/components/Vote.vue') },
	{ path: '/event', component: () => import('@/components/Event.vue') }
]