<template>
	<header class="header" :class="{ 'header--open': isMenuToggled }">
		<div class="wrapper header__wrapper">
			<a href="/home" class="header__logo"></a>
			<div class="header__toggle" @click="toggleMenu"><span class="header__toggle-inner"></span></div>
			<ul class="header__nav">
				<li><a class="header__item" href="/home">Home</a></li>
				<li><a class="header__item" href="/meet-aaron">Meet Aaron</a></li>
				<li><a class="header__item" href="/priorities">Priorities</a></li>
				<li><a class="header__item" href="/contact">Contact</a></li>
				<li><a class="header__item header__item--button button button--red" href="/vote">Join</a></li>
				<li><a class="header__item header__item--button button button--white" target="_blank" href="https://www.niprconservatives.ca/donate">Donate</a></li>
			</ul>
		</div>
	</header>
</template>

<script>
export default {
	created() {

	},
	data () {
		return {
			isMenuToggled: false
		}
	},
	computed: {

	},
	methods: {
		toggleMenu() {
			this.isMenuToggled = !this.isMenuToggled;
		}
	}
}
</script>

<style scoped>

.header { width: 100%; height: 90px; background: #062573; backdrop-filter: blur(5px); display: flex; }
.header__wrapper { display: flex; flex-wrap: wrap; align-items: center; }
.header__logo { flex:1; height: 60px; background: url('./assets/logo.svg') no-repeat; background-size: contain; }       
.header__nav { order: 1; list-style: none; display:flex; align-items: center; }
.header__item { }
.header__item:not(.header__item--button) { font-weight: 600; color: rgba(255, 255, 255, 0.7); font-size: 1.8rem; text-decoration: none; margin: 0 15px; text-transform: uppercase; }
.header__item:not(.header__item--button):hover { color: white; }
.header__item--button { margin-left: 20px; }
.header__toggle { order: 2; display: none; width: 30px; height: 23px; cursor: pointer; position: relative; }
.header__toggle-inner { position: absolute; top: calc(50% - 1.5px); } 
.header__toggle-inner::before { top: -10px; }
.header__toggle-inner::after { top: 10px; }
.header__toggle-inner, .header__toggle-inner::before, .header__toggle-inner::after { width: 100%; height: 3px; display: block; border-radius: 3px; background: white; transition: all 0.7s ease; }
.header__toggle-inner::before, .header__toggle-inner::after { content: " "; width: 100%; position: absolute; }

@media (max-width: 1100px) {
	.header__nav-item { margin: 0 15px; }
}

@media (max-width: 768px) {

	.header--open { background: #062573; height: auto; }
	.header--open .header__logo { margin: 15px 0; }
	.header__nav { display: none; height:0%; order:3; flex-basis: 100%; transition: all 0.7s ease; padding: 30px 0px; }
	.header--open .header__nav { display: flex; height: 100%; flex-direction: column; }
	.header__toggle { display: block; }
	.header--open .header__toggle { display: block; }
	.header--open .header__toggle-inner { height: 0; }
	.header--open .header__toggle-inner::before { transform: rotate(45deg); top:0; }
	.header--open .header__toggle-inner::after { transform: rotate(-45deg); top:0; }
	.header--open .header__toggle-inner,
	.header--open .header__toggle-inner::before,
	.header--open .header__toggle-inner::after { background: white; }
	.header__item:not(.button) { margin:0; padding: 20px 0; display: inline-block; text-align: center; width: 100%; font-size: 3rem; }
	.header__item.button { width: 100%; margin: 0; display: inline-block; box-sizing: border-box; text-align: center; padding: 15px 50px; margin-top: 20px; }
}

</style>
