<template>
<div id="app">
	<app-header></app-header>
	<router-view/>
	<app-footer></app-footer>
</div>
</template>

<script>
import AppHeader from '@/AppHeader.vue'
import AppFooter from '@/AppFooter.vue'
export default {
	mounted () {
	},
	components: {
		AppHeader, 
		AppFooter
	},
	data() {
		return {

		}
	},

	methods: {

	}

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

html { -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; font: 62.5%/1 sans-serif; }
body { margin:0; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; }
h1, h2, h3, h4, p, ol, ul { margin:0; padding:0; }
h1, h2, h3, h4 { font-size: inherit; font-weight: normal; }
a, button { color: inherit; }
a { text-decoration: none; }
img { vertical-align: top; max-width:100%; }
input, select, textarea, button { font: inherit; }
table { border-collapse: collapse; }

body { font: 1.8em "poppins", sans-serif; position: relative; line-height: 1; }

.wrapper { width: 100%; padding-left: 4%; padding-right: 4%; box-sizing: border-box; max-width: 1440px; margin: auto; }

.section { padding-top: 60px; padding-bottom: 60px; }
.section > .section:first-child { padding-top:0; }
.section--blue { background: linear-gradient(0deg, rgba(6, 33, 101, 0.90) 0%, rgba(6, 33, 101, 0.90) 100%); }
.section--overlay { position: relative; background-size: cover; background-position: center; background-repeat: no-repeat; }
.section--overlay::before { content: " "; position: absolute; background: linear-gradient(0deg, rgba(6, 37, 115, 0.80) 0%, rgba(6, 37, 115, 0.80) 100%); top:0; bottom:0; right:0; left:0; z-index: 1; }
.section--overlay > * { z-index: 2; position:relative; }
.section--center { text-align: center; }

.jumbotron { background-size: cover; background-position: center; background-repeat: no-repeat; }
.jumbotron--large { min-height: 500px; }

.play-button { display: flex; align-items: center; color: white; text-transform: uppercase; }
.play-button::before { content: " "; width: 45px; height: 45px; padding-right: 10px; display: block; background: url('./assets/play-button.svg'); background-size: contain; background-repeat: no-repeat; }

.video { width: 100%; max-width: 625px; height: 350px; margin-bottom: 20px; display: flex; align-items: flex-end; position: relative; overflow: hidden; z-index: 0; }
.video::before { content: " "; position: absolute; background: linear-gradient(to bottom, transparent, black); width: 100%; height: 100%; z-index: -1; }
.video__image { position: absolute; top: 0; z-index: -2; }
.video__play { margin: 20px; }

#video_player { display: none; position: fixed; z-index:9999; margin: auto; left: 0;right: 0; top: 0; bottom: 0; justify-content: center; align-items: center; }
#video_player::before { content: " "; z-index:-1; position: fixed; top:0; bottom:0; left:0; right:0; background: rgba(0,0,0,0.7); }
#video_player.video_player--open { display: flex; }

.box { padding: 30px; background: #F2F4F8; border: 1px solid #EAECF0; box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08); }
.box--blue { background: #00258F; }
.box--borderless { border: none; box-shadow: none; }

.button { background: transparent; appearance: none; outline:0; border:0; box-sizing: border-box; text-decoration: none; text-transform: uppercase; font-weight: bold; padding: 7px 15px; font-size: 1.8rem; cursor: pointer; display: inline-flex; align-items: center; justify-content: center; }
.button--white { background: white; color: #062573; }
.button--white:hover { background: #AAB7DA; }
.button--hollow { background: transparent; color: white; border: 2px solid white; }
.button--hollow:hover { background: rgba(255, 255, 255, 0.2); }
.button--hollow-blue { background: transparent; color: #062573; border: 2px solid #062573; }
.button--hollow-blue:hover { background: rgba(6, 37, 115, 0.1); }
.button--blue { background: #AAB7DA; color: white; }
.button--blue:hover { background:white; color: #062573; }
.button--blue-dark { background: #00258F; color: white; }
.button--blue-dark:hover { background:#AAB7DA; color: #062573; }
.button--red { background: #E0231C; color: white; }    
.button--red:hover { background: #A31E19; }
.button--large { padding: 14px 18px; }
.button:disabled { transition: background 0.5s; background: gray; color: white; }

.title { color: #101828; font-size: 3.3rem; text-transform: uppercase; font-weight: bold; line-height: 1; padding-bottom: 20px; }
.title--white { color: white; }
.title--red { color: #E0231C; }
.title--blue { color: #00258F; }
.title--small { font-size: 2.3rem; text-transform: none; }
.title--large { font-size: 4rem; }
.title--center { text-align: center; }

.text { padding-bottom: 20px; line-height: 1.5; font-size: 1.9rem; font-weight: normal; color: #475467; }
.text:last-child { padding-bottom: 0; }
.text--bold { font-weight: bold; }
.text--center { text-align: center; }
.text--white { color: white; }

.link { color: rgb(192, 0, 0); font-weight: bold; line-height: 1; }
.link--blue { color: #062573; }
.link--uppercase { text-transform: uppercase; }
.link:hover { text-decoration: underline; }

.list { list-style: circle; display: inline-flex; flex-direction: column; padding-left: 20px; text-align: left; }

.cover { width: 100%; height: 700px; display: flex; align-items: center; justify-content: center; background-repeat: no-repeat; background-size: cover; background-position: center 50%; position: relative; }
.cover::before { content: " "; position: absolute; background: linear-gradient(0deg, rgba(6, 37, 115, 0.80) 0%, rgba(6, 37, 115, 0.80) 100%); top:0; bottom:0; right:0; left:0; z-index: 1; }
.cover--small { height: 300px; }
.cover__content { display: flex; flex-direction: column; justify-content: center; z-index: 2; }
.cover__title { font-size: 5rem; color: white; font-weight: bold; text-transform: uppercase; z-index: 2; }
.cover__subtitle { font-size: 2.5rem; font-weight: 500; color: white;  }

.social { display: flex; margin: -20px 0 0 -20px; }
.social__item { width: 50px; padding: 20px 0 0 20px; text-decoration: none; color: #333; display: flex; align-items: center; font-weight: bold; justify-content: center; }
.social__item > * { width: 100%; }

.wide-image { width: 100%; height: 400px; object-fit: cover; margin: 40px 0; }

.share-buttons { margin: 15px 0 10px 0; display: flex; justify-content: center; }
.share-buttons > * { margin: 0 5px; }

.grid { width: 100%; display: flex; flex-wrap: wrap; align-items: flex-start; margin-left: -50px; }
.grid--center { align-items: center; }
.grid__item { padding-left: 50px; flex:1; box-sizing: border-box; position: relative; overflow: hidden; }
.grid__item--third { flex: calc(100%/3) 0 0; }

.qna { list-style: none; margin-top: 50px; }
.qna__item:not(:last-child) { margin-bottom: 50px; }
.qna__item:not(:last-child)::after { content: " "; width: 200px; border-bottom: 2px solid #CACACA; display: block; margin-top: 50px; }
.qna__question { color: #00256B; font-size: 2rem; font-weight: 600; margin-bottom: 10px; }

.events { list-style: none; }
.events__item { display: flex; margin-bottom: 20px; }
.events__content { background: white; color: #7282AA; box-sizing: border-box; }
.events__content:first-child { padding: 40px 50px; width: 70%; }
.events__content:last-child { padding: 40px 60px; margin-left: 2px; width: calc(40% - 2px); text-align: center; display: flex; flex-direction: column; justify-content: center; }
.events__location { display: flex; align-items: flex-end; margin-top: 20px; }
.events__icon { width: 40px; }
.events__city { text-transform: uppercase; color: #E0231C; font-weight: bold; font-size: 2rem; }
.events__date { color: #062573; font-size: 2rem; font-weight: 600; }

	@media (max-width: 1100px) {
		.grid { flex-direction: column; margin-left: 0; }
		.grid__item { padding-left: 0; }
		:not(.section) > .grid__item { padding-bottom: 40px; }
		.section:not(.section--blue):not(.section--overlay) + .section:not(.section--blue):not(.section--overlay) { padding-top: 0; }
		.box > .box  { margin: -30px; padding: 30px; box-sizing: content-box; }
	}

	@media (max-width: 768px) {
		.cover { height: auto; min-height: 300px; padding-top: 40px; padding-bottom: 40px; text-align: center; }

		.events__item  { flex-direction: column; }
		.events__content:first-child, .events__content:last-child { width: 100%; } 
		.events__content:last-child  { margin-left: 0px; padding-top: 0px; }
	}

	
</style>
