export default class {

    constructor() {

    }

    youtubeAPICall(url) {

        var httpRequest = new XMLHttpRequest();
        return new Promise(resolve => {
            httpRequest.onreadystatechange = () => {
                if (httpRequest.readyState !== 4) return;
                if (httpRequest.status >= 200 && httpRequest.status < 300) {
                    resolve(JSON.parse(httpRequest.response));
                }
            }
            httpRequest.open("GET", url, true);
            httpRequest.send();
        });
    }

    initAndGetPromise() {

        var responseJsonPromise = this.youtubeAPICall("https://aarongunn.ca/webservices/youtube_cache.json");
        var responseWebservicePromise = this.youtubeAPICall("https://aarongunn.ca/webservices/youtube.php");

        return Promise.race([responseJsonPromise, responseWebservicePromise]).then(response => {
            return { videos: response.videos, playlists: response.playlists };
        });
    }
}