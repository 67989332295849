<template>
  <footer class="footer">
     <div class="wrapper footer__wrapper">
         <div class="footer__section">
            <a href="/home" class="footer__logo"></a>
            <p>&copy; {{ (new Date()).getFullYear() }} Aaron Gunn.<br>All Rights Reserved.</p>
            <p><br>Authorized by the Financial Agent for the Aaron Gunn Campaign.</p>
         </div>
         <ul class="footer__section footer__nav">
            <li><a class="footer__item" href="/home">Home</a></li>
            <li><a class="footer__item" href="/meet-aaron">Meet Aaron</a></li>
            <li><a class="footer__item" href="/priorities">Priorities</a></li>
            <li><a class="footer__item" href="/contact">Contact</a></li>
            <li><a class="footer__item footer__item--button button button button--hollow" href="/vote">Join</a></li>
            <li><a class="footer__item footer__item--button button button button--blue" target="_blank" href="https://www.niprconservatives.ca/donate">Donate</a></li>
         </ul>
         <div class="footer__section">
            <h1 class="footer__title">Bring Back<br>Common Sense</h1>
            <h2 class="footer__follow-us">Follow us on Social Media</h2>
            <a href="https://www.youtube.com/aarongunnbc" target="_blank" title="Subscribe to my Youtube Channel">
               <img src="@/assets/social-icons/youtube.svg" alt="Youtube" class="footer__social-media"/>
            </a>
            <a href="https://twitter.com/AaronGunn" target="_blank" title="Follow me on X">
               <img src="@/assets/social-icons/x.svg" alt="Twitter" class="footer__social-media"/>
            </a>
            <a href="https://facebook.com/AaronGunn.ca" target="_blank" title="Follow me on Facebook">
               <img src="@/assets/social-icons/facebook.svg" alt="Facebook" class="footer__social-media"/>
            </a>
            <a href="https://www.instagram.com/aarongunnbc/" target="_blank" title="Follow me on Instagram">
               <img src="@/assets/social-icons/instagram.svg" alt="Instagram" class="footer__social-media"/>
            </a>
            <a href="https://ca.linkedin.com/in/aaron-gunn" target="_blank" title="Follow me on LinkedIn">
               <img src="@/assets/social-icons/linkedin.svg" alt="LinkedIn" class="footer__social-media"/>
            </a>
         </div>
     </div>
  </footer>
</template>

<script>
export default {
  created() {

  },
  data () {
    return {

    }
  },
  computed: {

  },
  methods: {

  }
}
</script>

<style scoped>

.footer { width: 100%; background: #062165; color: #7282AA; font-weight: 600; padding: 60px 0; }
.footer__wrapper { display: flex; }
.footer__section { width: calc(100% / 3); }
.footer__logo { width: 100%; height: 80px; display: inline-block; margin-bottom: 20px; background: url('./assets/logo.svg') no-repeat; background-size: contain; }  
.footer__nav { display: flex; flex-direction: column; list-style: none;  } 
.footer__item { text-transform: uppercase; display: inline-block; margin-bottom: 10px;  }
.footer__item:not(.footer__item--button):hover { color: white; }   
.footer__connected { display: flex; }
.footer__follow-us { margin: 15px 0 10px; }
.footer__social-media { height: 2rem; margin-right: 20px; }
.footer__title { font-size: 3rem; color: white; text-transform: uppercase; }

@media (max-width: 1100px) {

}

@media (max-width: 768px) {
  .footer__wrapper { flex-direction: column-reverse; align-items: center; text-align: center; }
  .footer__section { width: 100%; margin: 20px 0; }
  .footer__logo { background-position: center; }
}

</style>
