import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'

Vue.use(VueRouter);
Vue.config.productionTip = false

import routes from './routes.js'

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) return new Promise((resolve, reject) => { setTimeout(() => { resolve({ selector: to.hash }) }, 300) })
		else if (savedPosition) return savedPosition;
		else return { x: 0, y: 0 }
	},
});

router.beforeEach((to, from, next) => {

	var defaultTitle = "Help Elect Aaron Gunn";
	var defaultDescription = "To ensure Aaron becomes the next Conservative candidate in North Island—Powell River, register to vote for Aaron (and make sure your friends do too!) —but hurry, the time to register is running out!";
	var defaultFacebookPicture = "/facebook.jpg";
	var defaultTwitterPicture = "/twitter.jpg";

	var newTitle = to.meta.title || defaultTitle;
	var newDescription = to.meta.description || defaultDescription;
	var newFacebookPicture = 'https://aarongunn.ca' + (to.meta.socialImage || defaultFacebookPicture);
	var newTwitterPicture = 'https://aarongunn.ca' + (to.meta.socialImage || defaultTwitterPicture);

	document.querySelector('title').innerText = newTitle;
	document.querySelector('meta[name="description"]').setAttribute('content', newDescription);
	document.querySelector('meta[name="twitter:title"]').setAttribute('content', newTitle);
	document.querySelector('meta[name="twitter:description"]').setAttribute('content', newDescription);
	document.querySelector('meta[name="twitter:image"]').setAttribute('content', newTwitterPicture);
	document.querySelector('meta[property="og:title"]').setAttribute('content', newTitle);
	document.querySelector('meta[property="og:description"]').setAttribute('content', newDescription);
	document.querySelector('meta[property="og:image"]').setAttribute('content', newFacebookPicture);
	document.querySelector('meta[property="og:url"]').setAttribute('content', "https://aarongunn.ca" + to.path);

	next();
});

window.fbAsyncInit = function() {
	FB.init({ 
		appId: '262786994471808',
		status: true, 
		cookie: true, 
		xfbml: true,
		version: 'v9.0'
	});
};

(function() {
	console
	const initFacebookSDK = function(d, s, id) {
		var js, fjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) return;
		js = d.createElement(s); js.id = id;
		js.src = "https://connect.facebook.net/en_US/sdk.js";
		fjs.parentNode.insertBefore(js, fjs);
	}
	if(window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.prerenderRunning) return;
	initFacebookSDK(document, 'script', 'facebook-jssdk');
})();

var getYoutubeHelperPromise = () => {
	return new Promise(resolve => {
		var youtubeHelper = new YoutubeHelper();
		youtubeHelper.initAndGetPromise().then(response => {
			if(window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.prerenderRunning) { document.dispatchEvent(new Event('youtubeVideosLoaded')); }
			resolve(response);
		});
	})
}

import YoutubeHelper from '@/youtube.js'
Vue.prototype.$youtubeHelperPromise = getYoutubeHelperPromise();

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
